import React from 'react'
import Layout from '../components/layout'
import Branding from '../components/categories/branding'
import Seo from '../components/seo'

const BrandingPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Branding />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Branding Freelancers | Codestaff'
  const description =
    'Hire the best Branding freelancers at Codestaff. Get the top 1% of Branding professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default BrandingPage
